
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import vModal from '@/components/modals/v-modal.vue'
import vButton from '@/components/v-button.vue'
export default defineComponent ({
  name: 'VSectionIntegration',
  components: {
    vModal,
    vButton,
  },
  data() {
    return {
      modal: '',
    }
  },
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('integration', ['SERVICES']),
  },
  async mounted() {
    await this.GET_INTEGRATION_SERVIVES({ token: this.GET_AUTHENTIFICATED })
    setTimeout(() => {
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },
  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('integration', ['INTEGRATION', 'GET_INTEGRATION_SERVIVES']),
    async integration() {
      this.TOGGLE_PRELOADER(true)
      await this.INTEGRATION({
        token: this.GET_AUTHENTIFICATED,
        service: this.modal,
      })
      this.closeModal()
      this.TOGGLE_PRELOADER(false)
    },
    openModal(val: string) {
      this.modal = val
    },
    closeModal() {
      this.modal = ''
    },
  },
})
